import Footer from "../../components/Footer";
import Header from "../../components/Header";
import usePageTitle from "../../hooks/usePageTitle";
function SGR() {
	usePageTitle("Students' Grievances and Redressal Cell");
	return (
		<div>
			<Header />
			<div className="p-2" style={{ margin: "2rem auto", maxWidth: 600 }}>
				<h2 className="uppercase mb-4 font-bold">
					Students' Grievances and Redressal Cell
				</h2>
				<p className="pb-2">
					Grievances, if any related to COVID-19, teaching-learning process,
					examinations and other matter may be mailed to the Convenor, Students'
					Grievances and Redressal Cell (SGRC) at{" "}
					<a
						href="mailto:grc@ptuniv.edu.in"
						className="underline"
						style={{ color: "blue" }}
					>
						grc@ptuniv.edu.in
					</a>
				</p>
				<iframe
					title="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSdqdB8JGC1M-KHnwmC05puSBPLzDq6O4scr3l_LAK_ws9KcbA/viewform?embedded=true"
					width="640"
					height="2112"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
		<br></br>
		<br></br>
				<h2 className="uppercase mb-4 font-bold">
				Persons with Disabilities(PwDs) grievances redressal Cell
				</h2>
				
				<iframe
					title="form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSdV9RTtivks6L91dTAkHWBgZADSrq5NC2zUsvbiQ_4LSA5ANg/viewform?embedded=true"
					width="640"
					height="750"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
			</div>
			<Footer />
		</div>
	);
}

export default SGR;
