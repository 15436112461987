import { FILE_BASE_URL, SYLLABUS_BASE_URL } from "../../../config";

import Image from "../../../../src/components/assets/IT_HOD.png";

import Dimg from "../../../../src/components/assets/IT.jpg";

import img1 from "./facility/DSC_0003.jpg";
import img2 from "./facility/DSC_0971.jpg";
import img4 from "./facility/DSC_0987.jpg";
import img5 from "./facility/DSC_0992.jpg";

export const IT = {
	basePath: "/it",

	navLinks: [
		{
			to: "/about",
			text: "DEPARTMENT",
		},
		{
			to: "/faculty",
			text: "FACULTY",
		},
		{
			to: "/syllabus",
			text: "CURRICULUM",
		},
		{
			to: "/research",
			text: "RESEARCH",
		},
		{
			to: "/facilities",
			text: "FACILITIES",
		},
	],

	About: {
		DeptName: "Information Technology",
		ShortName: "IT",
		LongName: "DEPARTMENT OF INFORMATION TECHNOLOGY",

		DeptImg: Dimg,

		count: [
			{
				name: "B.Tech Students",
				count: 185,
			},
			{
				name: "M.Tech IoT Students",
				count: 12,
			},
			{
				name: "Ph.D Scholars",
				count: 13,
			},
		],

		about: [
			"The Department of Information Technology offers Under Graduate programme B.Tech Information Technology, Post Graduate programme M.Tech Internet of Thing (IoT) and Doctoral programme Ph.D. The Department is committed to the preparation of graduates to address the great challenges in the field of Information Technology and to contribute for research and innovation that ensure the sustained growth in Computing.",
			"The Department provides state-of-the-art computing facilities to the students enabling them to stay a step ahead. The courses are designed to combine rigorous academic study and exhaustive practical exposure for students with in-depth lab oriented courses, projects, and internships.",
			"The Department hosts a number of workshops and student managed symposiums. Neura is the official symposium of the department which attracts the participants all over India. The department has well experienced faculty, skilled technical staff and well equipped laboratories. The department also encourages innovative projects and quality research in various interrelated domains. The Department is involved in major research on which faculty members and students work on several areas such as Software Engineering, Image processing, Natural Language processing, Real time systems, Distributed computing, Mobile Networks, Data Mining, Big Data, Machine Learning and Internet of Things.",
		],
		vision: [
			{
				point:
					"To Produce Competent Professionals in Information Technology so as to Achieve the Global Innovation of Engineering and Technology",
			},
		],

		Mission: [
			{
				point:
					"To Provide High Quality Education and Training in Information Technology Through Advanced Learning Environment with State-of-the Art Facilities, Teaching Methodologies, Contemporary Curriculum and Research",
			},
		],

		PEOs: [
			{
				point: (
					<a href={FILE_BASE_URL + "it-b.tech.pdf"} target="_blank">
						B.Tech PEOs
					</a>
				),
			},
			{ point: "M.Tech" },
		],

		Hod: {
			name: "Dr. V. Geetha",
			designation: "Professor and Head",
			img: Image,
			phnNo: "9488821647",
			email: "hod.it@ptuniv.edu.in",
			content: `Welcome to Department of Information Technology. 
			Our Department imparts excellence in Engineering Education. 
			The Department of  IT strongly believes in building career and enriching young minds.
			The  Department offers the three  courses:
			1)  B.Tech in IT, 
			2)	M. Tech  (IoT),
			3)	Ph.D. 
			I am highly confident that the students of our  Department Students  will show a great level of professional competence.
			Wishing  You success in all your endeavours!`,
		},
	},
	departmentalLinks: [
		{
			text: "Departmental Committies",
			link: FILE_BASE_URL + "departmental-links/it-committees.pdf",
		},
		{
			text: "Notable Alumni",
			link: FILE_BASE_URL + "departmental-links/it-alumni.pdf",
		},
		{
			text: "Placement Details",
			link: FILE_BASE_URL + "departmental-links/it-placement.pdf",
		},
		{
			text: "Medals & Awards",
			link: FILE_BASE_URL + "departmental-links/it-medals-and-awards.pdf",
		},
		{
			text: "Retired Faculty",
			link: FILE_BASE_URL + "departmental-links/it-retired-faculty.pdf",
		},
		// {
		// 	text: "Students",
		// 	link: "",
		// },
		{
			text: "Events (Past & Upcoming)",
			link: FILE_BASE_URL + "departmental-links/it-events.pdf",
		},
	],
	Research: [
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
		{
			name: "",
			img: "",
			shortContent: "",
			longContent: "",
		},
	],
	curriculum: [
		{
			name: "B.Tech",
			desc: "A Bachelor of Technology is an undergraduate academic degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "8_BT_IT_2021.pdf",
				},
				{
					name: "AY 2018-19",
					link: SYLLABUS_BASE_URL + "8_BT_IT_1819.pdf",
				},
				{
					name: "AY 2014-15",
					link: SYLLABUS_BASE_URL + "8_BT_IT_1415.pdf",
				},
			],
		},
		{
			name: "M.Tech IOT",
			desc: "A degree conferred after the completion of a three-year, a four-year or a five-year program of studies at an accredited university or accredited university-level institution.",
			regulations: {},
			syllabusPdfLink: [
				{
					name: "AY 2020-21",
					link: SYLLABUS_BASE_URL + "19_MT_IT_IT_2021.pdf",
				},
			],
		},
	],
	facilities: [
		{
			name: "CASIT Laboratory ",
			img: img5,
			content:
				"Equipped with 35 Desktop computers with the configuration of Core 2 Duo, Core i3 and Core i5 processors",
		},
		{
			name: "Software Lab I ",
			img: img4,
			content:
				"Equipped with 35 Desktop computers with the configuration of Core i3 and Core i5 processors",
		},
		{
			name: "Software Lab II ",
			img: img1,
			content: "Equipped with 35 systems with Core i5 processors ",
		},
		{
			name: "IoT Lab ",
			img: img2,
			content:
				"Equipped with 20 numbers of Intel Core i5 systems,  Rasperry Pi and Arduino Uno Boards",
		},
		{
			name: "Hardware Lab ",
			img: img1,
			content:
				"Equipped with Digital Trainer kits, FPGA kits, 8085 and 8086 Microprocessor and 8051 Microcontroller kits",
		},
	],
};
