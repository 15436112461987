export const BASE_URL = process.env.REACT_APP_BASE_URL ?? "https://beta.ptuniv.edu.in/";
// export const BASE_URL = "http://192.168.0.110/cms-beta-hosted"; //for dev

const CMS_BASE_URL = "https://cms.ptuniv.edu.in/";
export default CMS_BASE_URL;

export const FILE_BASE_URL = CMS_BASE_URL + "file_contents/";

export const API_BASE_URL = CMS_BASE_URL + "API/";

export const IMG_BASE_URL = BASE_URL + "assets/images/";

export const PROFILE_IMG_BASE_URL = CMS_BASE_URL + "profilepic/";

export const SYLLABUS_BASE_URL = FILE_BASE_URL + "syllabus/";

export const ACADEMICS_FILE_BASE_URL = FILE_BASE_URL + "academics/";

export const FORMS_TEMPLATES_BASE_URL = FILE_BASE_URL + "forms-and-templates/";

export const CIRCULAR_BASE_URL = FILE_BASE_URL + "circular/";

export const getImageUrl = (relativeUrl) => {
	return IMG_BASE_URL + relativeUrl;
};

export const getProfileImageUrl = (relativeUrl) => {
	return PROFILE_IMG_BASE_URL + relativeUrl;
};
