import React, { useEffect, useRef, useState } from "react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

import "./TopLinks.css";

function TopLinks(props) {
	const { seperator, links } = props;
	const containerRef = useRef();
	const [showArrows, setShowArrows] = useState(false);

	useEffect(() => {
		if (containerRef.current?.scrollWidth > containerRef.current?.clientWidth) {
			setShowArrows(true);
		} else {
			setShowArrows(false);
		}
	}, [containerRef, setShowArrows]);

	const scrollLeft = () => {
		containerRef.current?.scrollBy(-150, 0);
	};
	const scrollRight = () => {
		containerRef.current?.scrollBy(150, 0);
	};

	return (
		<div className="quick-links__container">
			{showArrows && (
				<button className="arrows" onClick={scrollLeft}>
					<FaArrowAltCircleLeft />
				</button>
			)}
			<div ref={containerRef} className="quick-links__links hide-scrollbar">
				{links.map((link, index) => {
					if (link.type === "link") {
						const linkTag = (
							<a
								key={index}
								href={link.href}
								className="quick-links__link"
								target="_blank"
							>
								{link.text}
							</a>
						);
						if (index !== links.length - 1) {
							return [linkTag, seperator];
						}

						return linkTag;
					} else if (link.type === "dropdown") {
						const dropDownTag = (
							<div className="dropdown-item">
								<div className="dropdown-item-name">{link.text} ⮟</div>
								<div className="dropdown-modal-sheet">
									{link.dropdownlinks.map((item, index) => {
										return <a href={item.link}>{item.name}</a>;
									})}
								</div>
							</div>
						);
						if (index !== links.length - 1) {
							return [dropDownTag, seperator];
						}

						return dropDownTag;
					}
				})}
			</div>
			{/*<div className="portal">
				<a className="link_portal">Fee Portal</a>
				<div className="outer_portal">
					<div className="fee_portal">
						<a>a</a>
						<a>b</a>
						<a>c</a>
					</div>
				</div>
			</div>*/}
			{showArrows && (
				<button className="arrows" onClick={scrollRight}>
					<FaArrowAltCircleRight />
				</button>
			)}
		</div>
	);
}

export default TopLinks;

export function TopLinksContainer() {
	return <TopLinks seperator="|" links={links} />;
}

const links = [
	{
		type: "link",
		text: "Admin",
		href: "https://sites.google.com/ptuniv.edu.in/vcoffice/home",
	},
	{
		type: "link",
		text: "CoE",
		href: "https://sites.google.com/view/ptu-coe/home",
	},

	{
		type: "dropdown",
		text: "Fee Portal",
		dropdownlinks: [
			{ link: "https://payments.billdesk.com/bdcollect/bd/ptu/7692", name: "Academic Fee Portal" },
			{ link: "https://payments.billdesk.com/bdcollect/bd/puducherrytechuniv/7792", name: "Hostel Fee Portal" },
			{ link: "https://payments.billdesk.com/bdcollect/bd/ptu/10512", name: "Examination Fee Portal" },
		],
	},
	{ type: "link", text: "IEEE-SB", href: "https://www.ptuniv.edu.in/ieee-sb/" },
	{ type: "link", text: "IIS", href: "https://www.pytuiis.org/" },
	{ type: "link", text: "T&P", href: "https://tnp.ptuniv.edu.in" },
	{ type: "link", text: "Alumni", href: "https://pecaa.pec.edu" },
	{ type: "link", text: "Contact", href: "#main-footer" },
];
